body.woocommerce-order-received {
	h2 {
		display: none;
	}

	#breadcrumbs {
		display: none;
	}
	
	.woocommerce-thankyou-order-details {
		display: none;
	}
	.order_details {
		display: none;
	}
	.shop_table.order_details {
		display: none;
	}
	.shop_table.customer_details {
		display: none;
	}
	.col2-set.addresses {
		display: none;
	}
}