section#main-content {
    background: #fff;
    color: #2f3540;
    padding-bottom: 20px;
    
    @media (min-width: $screen-sm-min) {
        padding-bottom: 35px;
    }

    @media (min-width: $screen-md-min) {
        padding-bottom: 60px;
    }

    .page-title {
        text-align: center;
        margin-top: 25px;

        @media (min-width: $screen-sm-min) {
            margin-top: 35px;
        }

        @media (min-width: $screen-md-min) {
            margin-top: 55px;
        }

        .sub-title {
            color: #ccc9c0;
            display: block;
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 6px;

            @media (min-width: $screen-sm-min) {
                font-size: 14px;
            }
        }

        h1 {
            font-size: 24px;
            margin: 0;
            text-transform: uppercase;

            @media (min-width: $screen-sm-min) {
                font-size: 26px;
            }

            @media (min-width: $screen-md-min) {
                font-size: 32px;
            }

            &:after {
                background: #e6e4e0;
                content: '';
                display: block;
                height: 1px;
                margin: 22px auto 30px;
                width: 65px;
            }
        }
    }
}

.page-content {
    text-align: left;
    line-height: 2;
    table {
        th {
            background: $brand-secondary !important;
            font-weight: 300 !important;
            color: #fff;
        }
        td {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    h2 {
        text-align: center;
        margin: 30px 0 0px 0;
        line-height: 1.6;
        &:after {
            content: "";
            display: block;
            height: 1px;
            width: 65px;
            background: rgb(230, 228, 224);
            margin: 22px auto 30px;
        }
    }
    p {
        font-weight: 300;
        margin: 0 0 15px 0;
    }
}