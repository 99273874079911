.bundled_products {
	margin: 15px 0 30px 0;
	img {
		max-width: 80%;
		border: 1px solid #e6e7e8;
		padding: 10px;
	}
	.bundled_item_col {
		text-transform: uppercase;
		font-size: 14px !important;
		color: #5c6160 !important;
		font-weight: 300;
		font-family: 'Cinzel';
	}

	td {
		vertical-align: middle !important;
		h4, .quantity {
			font-family: 'Cinzel';
			font-size: 12px;
			color: #5c6160;
		}
	}
}

	.bundle_button {
		button[type='submit'] {
			width: calc(100% - 100px) !important;
		}
		.quantity {
		input {
			height: 45px !important;
			width: 45px !important;
			text-align: center !important;
		}
	}
		margin: 0 0 30px 0;
	}

	.cart_item.bundled_table_item {
		img {
			display: none !important;
		}
	}

	.bundled-product-name.bundled_table_item_indent {
		padding-left: 0 !important;
	}