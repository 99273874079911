section#product-categories {
    background: #fff;
    padding: 20px 0;

    /*
    .main {
        .category {
            height: 610px;
        }
    }
    */
    a {
        text-decoration: none;
    }
    
    .category {
        background: #e6e7e8 url() no-repeat center center;
        background-size: cover;
        height: 300px;
        margin: 15px 0;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media (min-width: $screen-sm-min) {
            height: 200px;
        }

        @media (min-width: $screen-md-min) {
            height: 220px;
        }

        @media (min-width: $screen-lg-min) {
            height: 290px;
        }
        /*
        &.large {
            height: 250px;

            @media (min-width: $screen-sm-min) {
                height: 300px;
            }

            @media (min-width: $screen-md-min) {
                height: 400px;
            }

            @media (min-width: $screen-lg-min) {
                height: 510px;
            }
        }
        */

        .title {
            background: rgba(#001323, .6);
            padding: 10px;
            text-align: center;
            width: 100%;
            
            .sub-title {
                color: #fff;
                font-style: italic;
                display: block;
                font-weight: 300;
                margin-bottom: 3px;
            }

            h3 {
                font-size: 18px;
                text-transform: uppercase;
                margin: 0;

                @media (min-width: $screen-sm-min) {
                    font-size: 21px;
                }

                @media (min-width: $screen-md-min) {
                    font-size: 21px
                }
            }
        }
    }
}
