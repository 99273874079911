footer#main-footer {
    font-weight: 300;
    padding-top: 5px;

    h3 {
        color: #b2bdb7;
        font-size: 16px;
        margin-bottom: 13px;
    }

    ul {
        float: left;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 50%;

        li {
            line-height: 29px;
        }
    }

    .product-categories {

    }

    .quick-links {

    }

    .shop-locations {
        .opening-times {
            color: #ccc9c0;

            dl {
                display: flex;
                flex-flow: column;
                flex-wrap: wrap;
                overflow: visible;
                text-align: left;
                width: 100%;

                @media (min-width: $screen-sm-min) {
                    -webkit-box-orient: vertical;
                    flex-flow: row;
                    flex-wrap: wrap;
                }

                dt {
                    flex: 0 0 20%;
                    font-weight: 300;
                    margin-bottom: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media (min-width: $screen-sm-min) {
                        margin-bottom: 0;
                    }
                }

                dd {
                    flex: 0 0 80%;
                    margin-left: 0;
                    overflow: hidden;
                    text-align: left;
                    text-overflow: ellipsis;

                    @media (min-width: $screen-sm-min) {
                        margin-left: auto;
                    }
                }
            }
        }

        .location {
            margin-bottom: 33px;

            &:last-of-type {
                margin: 0;
            }

            .address {

                @media (max-width: $screen-lg) {
                    font-size: 13px;
                }

                @media (min-width: $screen-lg-min) {
                    margin-right: 15px;
                }
            }

            .tel {
                display: block;
                margin-top: 5px;

                @media (min-width: $screen-lg-min) {
                    // display: inline-block;
                    margin-left: 0;
                }
            }

            .email {
                display: block;
                margin: 6px 0 0 0;
            }
        }
    }

    .rule {
        background: #69787b;
        height: 2px;
        margin: 20px 0 10px;
        width: 100%;
    }

    .bottom {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .copyright {
            font-size: 11px;
            text-align: center;
            margin: 0;

            @media (min-width: $screen-sm-min) {
                text-align: left;
            }

            a {
                color: #8dc63f;
            }
        }

        .social-links {
            float: none;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            @media (min-width: $screen-sm-min) {
                margin-top: 0;
                text-align: right;
                justify-content: flex-end;
            }

            .social-links-icon {
                display: inline-block;
                line-height: $line-height-base;
                vertical-align: bottom;

                a {
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                    background: #ffffff;
                    color: $brand-primary;
                    display: block;
                    font-size: 13px;
                    height: 24px;
                    text-align: center;
                    width: 24px;
                    margin-left: 8px;
                    text-decoration: none;

                    @media (min-width: $screen-sm-min) {
                        font-size: 14px;
                        height: 24px;
                        width: 24px;
                    }

                    &:hover {
                        color: #ffffff;
                        background: transparent;       
                    }

                    span {
                        line-height: 22px;
                        margin: 0 auto;
                        display: block;
                        text-align: center;
                        padding: 0 0 0 1px;
                        @media (min-width: $screen-sm-min) {
                            line-height: 22px;
                            padding: 0;
                        }
                    }
                }
            }

            .social-links-email {
                display: none;
                @media (min-width: $screen-md-min) {
                    display: block;
                }
                a {
                    color: #e6e7e8;
                    font-size: 11px;
                    font-weight: 300;
                } 
            }

            .social-email-icon {
                display: block;
                @media (min-width: $screen-md-min) {
                    display: none;
                }
            }
        }
    }
}