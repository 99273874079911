section#hero {
    .hero-image {
        background: url() no-repeat center center;
        background-size: cover;
        height: 120px;
        
        @media (min-width: $screen-sm-min) {
            height: 170px;
        }
        
        @media (min-width: $screen-md-min) {
            height: 200px;
        }

        @media (min-width: $screen-lg-min) {
            height: 280px;
        }
    }
}