.composite_form {
	margin-top: 30px!important;
	margin-bottom: 0!important;

	.composite_navigation {
		display: none!important;
	}

	&.componentized {
		.composite_navigation.bottom {
			display: block!important;
			.composite_navigation_inner {
				padding: 0;
				margin: 0;
				background-color: #f9f9f9;

				.page_button {
					display: block;
					margin: 0!important;
					//width: 100%;
					background-color: #839795;
					font-family: "Cinzel", serif;
					font-weight: 300;
					text-transform: uppercase;
					font-size: 14px;
					text-decoration: none;
					&:hover {
						color: white;
					}
					&.next {
						padding: 10px 30px 10px 15px;
						&::after {
							right: 12px;
						}
					}
					&.prev {
						padding: 10px 15px 10px 30px;
						&::before {
							left: 12px;
						}
					}
				}
			}
		}
	}

	.composite_data {
		.composite_navigation, .composite_navigation.bottom {
			display: none!important;
		}
	}
	
	.composite_pagination {
		display: block!important;
		margin: 0 0 30px 0!important;
		.pagination_elements {
			margin: 0;
			padding: 0;

			.pagination_element {
				background: transparent;
				float: none;
				display: block;
				max-width: 100%;
				width: 100%;
				margin: 0;
				border: solid 1px #dadbdc;
				//border-bottom: none;
				transition: all 250ms;
				cursor: pointer;
				&::before, &::after {
					display: none;
				}
				&:hover {
					background: #839795;
					border-color: transparent;
					.element_index, .element_link {
						color: white;
					}
				}


				.element_index {
					margin-right: 10px;
				}

				.element_link {
					transition: all 250ms;
				}

				.element_index, .element_link {
					color: #2f3540;
					font-weight: 300px;
					font-size: 14px;
					font-family: "Cinzel", serif;
					text-transform: uppercase;
					font-weight: 300;
					text-decoration: none;
					&:active {
						text-decoration: none;
					}
				}

				&_current {
					background: #839795;
					border-color: transparent;
					.element_index, .element_link {
						color: white;
					}
				}
			}
		}
	}



	.composite_summary, .composite_component {
		.step_title_wrapper {
			display: block;
		    background-color: #839795;
		    color: white;
		    padding: 10px 15px;
		    margin: 0;
		    font-size: 20px;
		    .step_index {
		    	padding-left: 0;
		    }
		    .component_title_text {
				&::before {
					left: 10px;
				}
		    }
		}

		.component_inner, .summary_elements {
			padding: 15px 10px!important;
			background-color: #f9f9f9;
		}
	}

	.composite_summary {
		.summary_element {
			.summary_element_wrapper_outer {
				.summary_element_wrapper_inner {
					padding: 0!important;

					.summary_element_title {
						min-height: 35px;
					}

					.summary_element_image .summary_element_content {
						width: 100%;
					}

					.summary_element_selection .summary_element_content {
						font-size: 14px;
					}

					.summary_element_price .summary_element_content {
						font-size: 17.5px;
						margin: 5px 0 0 0;
					}

					.summary_element_button .summary_element_select{
						float: none!important;
						line-height: 1;
						width: 100%;
						font-size: 14px;
						font-family: "Cinzel", serif;
						font-weight: 400;
						background-color: #839795;
						color: white;
					}
				}
			}
		}
	}
	
	.composite_component {
		.component_inner {
			.component_option_thumbnail_container {
				margin-bottom: 10px!important;
				.component_option_thumbnail {
					.thumbnail_image img {
						width: 100%;
						height: auto;
					}

					.thumbnail_buttons {
						padding: 0!important;
						.button {
							float: none!important;
						}
					}
				}
			}

			label {
				font-weight: 400!important;
			}

			.component_content {
				//background-color: red!important;
				height: auto!important;

				.component_data {
					.component_wrap {
						font-weight: 300;
						.price {
							font-weight: 400!important;
						}
					}
				}
				
				.component_summary {

					.composited_product_details_wrapper {
						.attribute_options {
							.label {
								text-align: left;
							}

							.select {
								margin: 0!important;
							}

							.reset_variations {
								margin: 0!important;
								line-height: 1;
								font-size: 14px;
								color: #a91d44;
							}
						}

						.woocommerce-variation-price {
							margin: 0;
							.price {
								margin: 6px 0 0 0;
								font-size: 17.5px!important;
								color: #77a464!important;
							}
						}
					}

					.component_options_select {
						margin: 0!important;
					}

					.composited_product_title_wrapper {
						padding: 0;
						.composited_product_title {
							font-family: "Cinzel", serif;
							font-size: 18px;
							font-weight: 400;
						}
					}

					.clear_component_options {
						color: #a91d44;
					}
				}

			}

					
		}



		&.multistep {
			//background-color: blue;
		}	
	}

	.composite_data {
		//background-color: red;
		.composite_button {
			display: flex;
			@media(max-width: $screen-sm) {
				display: block;
			}
			.quantity {
				float: none!important;
				min-width: 165px;
				&::before {
					content: 'Quantity: ';
				    display: inline-block;
				    font-size: 12px;
				    color: #343434;
				    font-family: "Cinzel", serif;
				    text-transform: uppercase;
				    margin-right: 25px;
				    font-weight: 700;
				}

				.input-text {
					display: inline-block;
				    width: 45px;
				    height: 45px;
				    border: 1px solid #e9eaeb;
				}
			}

			.composite_add_to_cart_button {
				width: 100%;
				float: none;
				display: inline-block;
				font-weight: 100;
				@media(max-width: $screen-sm) {
					margin-top: 30px!important;
				}
			}

		}


	}

}

