#loader{

	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(71, 81, 89, 0.6784313725490196); 
	z-index: 9999;
	align-items: center;
    justify-content: center;

    span{

    	i{

    		font-size: 55px;
    	}
    }


}