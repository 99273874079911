section#news {
    background: #fff;
    padding: 17px 0 55px;

    article {
        display: flex;
        flex-direction: column;
        height: 375px;
        margin-bottom: 15px;
        margin-top: 15px;

        @media (min-width: $screen-sm-min) {
            height: 550px;
        }

        @media (min-width: $screen-md-min) {
            height: 660px;
        }

        &:nth-of-type(2n) {
            flex-direction: column-reverse;
        }

        .post-content {
            background: #e6e7e8;
            padding: 20px;

            @media (min-width: $screen-sm-min) {
                padding: 30px 50px 35px;
            }

            h1 {
                color: #2f3540;
                font-size: 16px;
                margin: 0;
                position: relative;
                text-align: center;

                &:after {
                    background: #cecbc3;
                    content: '';
                    display: block;
                    height: 1px;
                    margin: 12px 0 20px;
                    width: 100%;
                }
            }

            p {
                color: #2f3540;
                font-weight: 300;
                text-align: center;
                padding: 0 15px;
            }

            a {
                border-color: #839795;
                color: #b2bdb7;
                display: block;
                margin: 17px auto 0;
                text-transform: uppercase;
                width: 165px;

                &:active,
                &:focus,
                &:hover {
                    background: #839795;
                    color: #fff;
                }
            }
        }

        .post-image {
            background: #e6e7e8 url() no-repeat center center;
            background-size: cover;
            flex: 1;
            width: 100%;
        }
    }

    a#load-more-articles {
        margin-top: 42px;
        padding: 11px 14px;
        text-transform: uppercase;
        width: 100%;
    }
}