section#slider {
    background: #e6e7e8;
    @media(max-width: $screen-sm) {
        min-height: calc(100vh - 237px);
    }

    img {
        width: 100%;
        height: 100%;
        @media(max-width: $screen-sm) {
            height: calc(100vh - 237px);
            height: 400px;
            object-fit: cover;
        }
    }

    .slide {
        background: url() no-repeat center center;
        background-size: cover;
        position: relative;
        overflow: hidden;
        height: 100%;
        @media (min-width: $screen-lg-min) {
            max-height: 800px;
        }

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: 0;
            -ms-transform: translateX(-50%) translateY(-50%);
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
            @media (max-width: $screen-sm-min) {
               display: none; 
            }
        }

    
        .overlay {
            background: rgba(#000, 0.4);
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;

            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }

        .content {
            padding: 0 20px;
            max-width: 370px;
            position: absolute;
            text-align: right;
            top: 50%;
            transform: translate(50%, -50%);
            right: 50%;
            z-index: 30;
            text-align:center;

            @media (min-width: $screen-sm-min) {
                left: 9%;
                margin: 0;
                right: auto;
                transform: translateY(-50%);
                background-color: rgba(0, 0, 0, 0.4);
                padding: 30px;
            }

            h3 {
                margin: 0;
                position: relative;
                text-transform: uppercase;

                @media (min-width: $screen-sm-min) {
                    font-size: 32px;
                }

                &:after {
                    background: #a0a9a9;
                    content: '';
                    display: block;
                    height: 1px;
                    margin: 15px 0;

                    @media (min-width: $screen-sm-min) {
                        height: 2px;
                    }
                }
            }

            p {
                font-weight: 300;

                @media (max-width: $screen-xs-max) {
                    font-size: 12px;
                }
            }

            .btn {
                background: rgba(#fff, .75);
                border-color: rgba(#fff, .75);
                margin-top: 10px;
                text-transform: uppercase;
                color: rgba(#fff, .75);

                @media (min-width: $screen-sm-min) {
                    background: transparent;
                    //border-color: #475159;
                    //color: #475159;
                }
            }
        }
    }

    button {
        background: transparent;
        border: 0;
        font-size: 35px;
        height: 35px;
        outline: none;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 120;

        &.slick-next {
            right: 22px;
        }

        &.slick-prev {
            left: 22px;
        }

        span {
            position: relative;
            top: -5px;
        }
    }
     .slick-slide {
        height: auto;
      }
      .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
      }
}