.woocommerce-ordering {
	display: none;
}

nav.woocommerce-pagination, .pagination {
	.pagination-title {
		display: none;
	}
	ul {
		padding: 0;
		margin: 0;
		border: none !important;
		list-style-type: none;
	}
	ul li {
		float: left;
		border: none !important;
		font-family: $font-family-serif;
		a {
			color: $brand-primary;
		}
		span.current {
			background: none !important;
			font-weight: bold;
		}
		span.page-numbers {
			color: $brand-primary;
		}
	}
}
