// Colours.
$brand-primary: #475159;
$brand-secondary: #839795;
$brand-third: #dadbdc;

$body-bg: $brand-primary;
$text-color: #fff;
$link-color: #fff;

// Buttons.
$btn-border-radius-base: 0px;
$btn-border-radius-large: 0px;
$btn-border-radius-small: 0px;

// Inputs.
$input-border-radius: 0px;
$input-border-radius-large: 0px;
$input-border-radius-small: 0px;
$input-bg: transparent;
$input-color-placeholder: #fff;
$input-color: #fff;

// Fonts.
$font-family-serif: 'Cinzel', serif;
$font-family-sans-serif: 'Lato', sans-serif;
$headings-font-family: $font-family-serif;
