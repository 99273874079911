section#newsletter {
    background: #839795 url(../images/background-repeat.png);
    padding: 52px 0;

    h1 {
        font-size: 25px;
        margin: 0;
        text-transform: uppercase;

        &:after {
            background: #9aaaa6;
            content: '';
            display: block;
            height: 2px;
            margin: 16px 0 18px;
            width: 160px;
        }
    }

    p {
        font-weight: 300;

        &:last-of-type {
            margin: 0;
        }
    }

    form {
        margin-top: 40px;

        .form-group {
            margin-bottom: 20px;
        }

        input {
            font-weight: 300;
            height: 37px;
            &[type="submit"] {
                font-weight: 300;
                width: 100%;
                text-transform: uppercase;
            }
        }
    }
}