#my-account {

	.woocommerce-MyAccount-navigation ul li {
		background-color: #a91d44;
		color: #2F353F;
		line-height: 1.3;
		a {
			text-decoration: none;
		}
	}

	p {
		a {
			color: #a91d44;
		}
	}
	.woocommerce-MyAccount-content {
		padding-top: 25px;
		padding-bottom: 25px;
	}

	input {
	    border: 1px solid #c1c1c1;
	}
}