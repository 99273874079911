section#hero-news {
    height: 300px;
    position: relative;

    @media (min-width: $screen-sm-min) {
        height: 350px;
    }

    @media (min-width: $screen-md-min) {
        height: 500px;
    }

    .hero-image {
        background: url() no-repeat center center;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .container {
        position: relative;
        height: 100%;
    }

    .news-details {
        background: rgba(#000, .7);
        bottom: 0;
        left: 0;
        padding: 20px;
        position: absolute;
        text-align: center;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            left: 15px;
            max-width: 580px;
            padding: 50px 50px 30px;
        }

        h1 {
            font-size: 22px;
            margin: 0;

            @media (min-width: $screen-sm-min) {
                font-size: 32px;
            }

            &:after {
                content: '';
                display: block;
                margin: 17px auto 20px;
                width: 100%;
                height: 1px;
                background: rgba(#fff, .7)
            }
        }

        .meta {
            font-size: 15px;
            font-weight: 300;

            .date {
                margin-right: 20px;
            }

            .author {

            }
        }
    }
}