section#news-article {
    background: #fff;



    .article-content {
        .banner-image {
            height: 400px;
            width: 100%;
            background-color: lightgrey;
            margin-bottom: 60px;
            background-size: cover;
            background-position: center center;
            @media (max-width: $screen-sm-max) {
                height: 200px;
                margin-bottom: 30px;
            }
        }
        padding-top: 35px;
        color: #000;

        a {
            color: #206590;
            text-decoration: underline;
            }

        p {
            color: #2f3540;
            font-weight: 300;
            // text-align: center;

            &:first-of-type {
                font-size: 16px;
                margin-bottom: 15px;
            }
        }

        .images {
            margin: 20px 0;

            @media (min-width: $screen-sm-min) {
                margin: 55px 0 40px
            }

            img {
                height: auto;
                margin: 15px 0;
                width: 100%;

                @media (min-width: $screen-sm-min) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .article-footer {
        border-top: 1px solid #d3d4d5;
        margin-top: 25px;
        padding: 20px 0;

        @media (min-width: $screen-sm-min) {
            margin-top: 40px;
            padding: 33px 0 20px;
        }

        a.back {
            border-color: #93a4a2;
            color: #b2bdb7;
            text-transform: uppercase;
            width: 100%;
        }
        
        .share-links {
            margin-top: 20px;
            text-align: center;

            @media (min-width: $screen-sm-min) {
                margin-top: 0;
                text-align: right;
            }

            .share-label {
                color: #343434;
                display: block;
                font-family: $font-family-serif;
                font-size: 15px;
                margin-bottom: 10px;
                margin-right: 15px;
                text-transform: uppercase;
                vertical-align: middle;

                @media (min-width: $screen-sm-min) {
                    display: inline-block;
                    font-size: 12px;
                    margin-bottom: 0;
                }
            }
            
            .a2a_kit {
                display: inline-block;
                vertical-align: middle;

                a {
                    display: inline-block;
                    border: 1px solid $brand-secondary;
                    border-radius: 50%;
                    color: $brand-secondary;
                    font-size: 27px;
                    height: 46px;
                    line-height: 45px;
                    margin: 2px 10px 0;
                    text-align: center;
                    width: 46px;
                    cursor: pointer;

                    @media (min-width: $screen-sm-min) {
                        margin: 2px 22px 0;

                    }

                    &:first-of-type {
                        margin-left: 0;

                        @media (min-width: $screen-sm-min) {
                            margin-left: 22px;
                        }
                    }

                    &:active,
                    &:focus,
                    &:hover {
                        background: $brand-secondary;
                        color: #fff;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &.a2a_button_google_plus {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
