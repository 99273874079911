#cart-totals {
	.checkout-button {
		background: #a91d44;
		font-family: $font-family-serif;
		font-size: 20px;
		color: #fff;
	}
}

#cart-message {
	.inner {
		border: 1px solid #e4e4e4;
		padding: 25px;
	}
}
p.return-to-shop {
	margin: 0 !important;
	height: 43px !important;
	float: right !important;
	@media (max-width: $screen-sm) {
		margin: 10px 0 !important;
		width: 100%;
		float: none;
		text-align: center;
	}
	.button {
		background-color: #a91d44 !important;
	   	font-family: "Cinzel", serif !important;
	    font-size: 14px !important;
	    text-transform: uppercase !important;
	    font-weight: 100 !important;
	    color: white !important;
	    padding: 15px 15px !important;
	}
}