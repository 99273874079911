#brands-list {
	background-color: #fff;
	color: #475159;
	.brand-category-content {
		margin: 0 0 25px 0;
		border-bottom: 4px solid #475159;
		padding: 0 0 25px 0;
		@media(max-width: $screen-sm) {
			margin: 0 0 10px 0;
			padding: 0 0 10px 0px;
			border-bottom: 2px solid #475159;
		}
	}
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		li {
			font-size: 18px;
			margin: 0 0 15px 0;
			font-family: 'Cinzel';
			a {
				color: #313131;
				text-decoration: underline;
			}
			@media(max-width: $screen-sm) {
				font-size: 14px;
			}
		}
	}
	.brand-category {
		font-size: 50px;
		font-weight: 800;
		padding: 0 0 15px 0;
		margin: 0 0 15px 0;
		@media(max-width: $screen-sm) {
			padding: 0 0 10px 0;
			margin: 0 0 15px 0px;
			font-size: 30px;
		}
	}
}