#cart {
	table {
		border-radius: 0px;
		th {
			font-family: $font-family-sans-serif;
			font-weight: 100;
			text-transform: uppercase;
		}
		
		img {
			width: auto;
			max-width: 100%;
			max-height: 70px;
		}

		.product-name a {
			font-family: $font-family-serif;
			color: #2f3540;
		}


		.button {
			background-color: #a91d44 !important;
			font-family: $font-family-serif;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 100;
			height: 43px;
		}

	}


}