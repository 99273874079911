.woocommerce-info {
	a {
		color: #a91d44;
	}
}

#checkout {
	text-align: left;

	input,select {
		border: 1px solid #e4e4e4;
	}

	.woocommerce-validated {
		input,select {
			border-color: green;
		}
	}

	.woocommerce-invalid {
		input,select {
			border-color: red;
		}
	}

	h3 {
		border-bottom: 1px solid #e4e4e4;
		padding-bottom: 15px;
		margin-bottom: 15px;
		float: none !important;
	}	



	#place_order {
		background-color: #a91d44;
	    font-family: 'Cinzel';
	    width: 300px;
	    max-width: 250px;
	    font-weight: 500;
	}

	input[type='check'] {
		display: inline-block;
		vertical-align: middle;
	}

	input[type='radio'] {
			display: inline-block;
			vertical-align: middle;
	}

	label {
		text-transform: uppercase;
		font-family: $font-family-sans-serif;
		font-weight: 100;
	}
	
	input, select {
		height: 43px;
		padding: 5px;
	}

	textarea {
		padding: 5px;
		min-height: 120px;
	}

	#ship-to-different-address {
		position: relative;
		padding-left: 25px;
	}

	.woocommerce-validated {
		input {
			// border-color: #
		}
	}

	.woocommerce-checkout {

	.create-account {
		border-top: 1px solid #e4e4e4;
		border-bottom: 1px solid #e4e4e4;
		margin-top: 30px;
		p {
			padding-top: 30px;
		}
		.form-row {
			padding-bottom: 30px;
			label {
				padding: 0;
				font-size: 13px;
			}
		}
	}


	#createaccount {
		position: absolute;
		left: 0;
		height: 15px;
		width :15px;
		top: 50%;
		transform: translateY(-50%);
	}

	.create-account {
		position: relative;
		label {
			padding-left: 22px;
			font-size: 24px;
		}
	}


	}


	#ship-to-different-address input {
	    position: absolute;
	    left: 0px;
	    height: 15px;
 		top: 1px;
	}
}