section#contact {
    background: #839795 url('../images/background-repeat.png');
    position: relative;

    .wpcf7-validation-errors {
        // display: none;
    }

    .addresses {
        width: 100%;

        @media (min-width: $screen-md-min) {
            height: 100%;
            left: 0;
            position: absolute;
            // width: calc(50% - 25px);
        }

        .address {
            background: $brand-secondary;
            color: #fff;
            display: block;
            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;
            text-align: center;

            @media (min-width: $screen-md-min) {
                // float: left;
                height: 100%;
                width: 50%;
                text-align: right;
                &:first-child {
                  border-right: 1px solid #fff;
                //   width: calc(50% - 1px);
                }
            }

            &:nth-of-type(2n) {
                background: $brand-primary;

                @media (min-width: $screen-sm-min) {
                     .details {
                        text-align: left;
                    }
                }
            }

            .details {
                // display: none;
                padding: 20px 10%;
                // min-height: 361px;
                width: 49%;
                display: inline-block;
                vertical-align:top;

                @media (min-width: $screen-md-min) {
                    // padding: 20px 15%;
                }

                 @media (min-width: $screen-lg-min) {
                    // padding: 22px 20%;
                }

                h3 {
                    font-size: 15px;
                    margin: 0 0 17px;
                    text-transform: uppercase;
                }

                p {
                    font-weight: 300;
                    margin-bottom: 25px;
                }

                .tel {
                    color: #fff;
                    font-weight: 300;
                }

                .email {
                    display: block;
                    margin-top: 2px;
                    color: #fff;
                    font-weight: 300;
                }

                .opening_hours{

                    margin-top: 17px;

                    dl{

                        font-family: $font-family-serif;
                        margin-bottom: 0;

                        dt{
                            font-size: 15px;
                            text-transform: uppercase;
                            font-weight: 500;
                            margin-bottom: 17px;

                        }

                        dd{

                            font-family: $font-family-sans-serif;
                            font-size: 14px;
                            margin-bottom: 2px;
                            text-transform: normal;
                            font-weight: 300;

                            &:last-child{
                                
                                margin-bottom: 0;                                    

                            }

                        }
                    }


                }

            }

            .acf-map {
                background: #eaeaea;
                height: 100%;
                width: 100%;
                display: inline-block;
                width: 49%;
                vertical-align:top;
                min-height: 370px;

                // @media (min-width: $screen-md-min) {
                //     height: 290px;
                // }
            }
        }
    }

    .contact-form {
        margin-top: 30px;
        margin-bottom: 30px;

        form {
          max-width:50%;
          margin: 0 auto;

          @media(max-width:$screen-md) {
            max-width:100%;
          }

        }


        @media (min-width: $screen-sm-min) {
            margin-top: 50px;
            margin-bottom: 60px;
        }

        @media (min-width: $screen-md-min) {
            margin-top: 64px;
            margin-bottom: 80px;
        }
        input {
            width: 100%;
        }
        input,
        select,
        textarea {
            border-color: #475059;
            background-color: #475059;
            font-weight: 300;
            height: 36px;
        }

        textarea {
            min-height: 130px;
        }

        .wpcf7-not-valid {
            border: 1px solid #a91d44;
        }

        .wpcf7-not-valid-tip {
            // display: none;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .wpcf7-validation-errors {
            // display: none !important;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }


        .form-group {
            margin-bottom: 25px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .btn-submit {
            text-transform: uppercase;
            display: inline-block;
        }
    }
}
