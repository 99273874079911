section#cross-sells {
    background: #fff;
    padding: 50px 0 0px;

    &.alternative-bg {
        background-color: #e6e7e8;
    }

    .section-title {
        h1 {
            margin-bottom: 60px;

            @media (max-width: $screen-sm) {
                font-size: 22px;
            }


        }
    }

    .slick-slide {
        height: auto;
    }

    .slick-track {
        margin-left: 0;
    }

    .slider-arrow {
        background: none;
        border: none;
        color: #bec0c2;
        font-size: 38px;
        //margin-top: 12px;
        padding: 0;
        z-index: 20;

        &#cross-sells-prev {
            margin-left: 20px;
            @media (max-width: $screen-sm) {
                margin-left: 10px;
            }
        }

        &#cross-sells-next {
            float: right;
            margin-right: 20px;
            @media (max-width: $screen-sm) {
                margin-right: 10px;
            }
        }

        span {
            display: block;
        }
    }

    .cross-sells {
        h2 {
            color: $brand-primary;
        }
    }
}