section#breadcrumb {
    background: #fff;

    #breadcrumbs {
        border-bottom: 2px solid #c1cbca;
        color: #4f4f4f;
        font-family: $font-family-serif;
        font-size: 12px;
        margin: 12px 0 10px;
        padding-bottom: 10px;
        text-transform: uppercase;

        span.breadcrumb_last {
            color: #919191;
        }

        a {
            color: #4f4f4f;
        }
    }
}