section#featured-brands {
    background: #e6e7e8;
    padding: 15px 0 30px;

    .slider-arrow {
        background: none;
        border: none;
        color: #bec0c2;
        font-size: 38px;
        margin-top: 12px;
        padding: 0;

        &#brands-prev {
            margin-left: 20px;
        }

        &#brands-next {
            float: right;
            margin-right: 20px;
        }

        span {
            display: block;
        }
    }

    .slick-slide {
        height: 70px;
    }

    .section-title {

        h1 {

            @media (max-width: 768px) {
                font-size: 22px;
            }

        }
    }

    .brands {
        margin-top: 20px;
        
        @media (min-width: $screen-sm-min) {
            margin-top: 50px;
        }

        .brand {
            background: #fff;
            border: 1px solid #fff;
            height: 70px;
            text-align: center;
        }

        img {
            margin: 0 auto;
            max-height: 100%;
            padding: 10px;
            // position: relative;
            // top: 50%;
            // transform: translateY(-50%);
        }
    }
}