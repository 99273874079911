
textarea {
    max-width: 100%;
}

.btn {
    padding: 8px 14px;
}

a,
.btn {
    transition: background .3s ease-in-out, border .3s ease-in-out, color .3s ease-in-out;
}

.btn-trans {
    border: 1px solid #fff;

    &:hover {
        background: #fff;
        color: $brand-primary;
    }

    &.dark {
        border-color: $brand-primary;
        color: $brand-primary;

        &:hover {
            background: $brand-primary;
            color: #fff;
        }
    }
}


.section-title {
    text-align: center;

    .sub-title {
        color: #839795;
        display: block;
        font-weight: 300;
        margin-bottom: 3px;
    }

    h1 {
        color: #475159;
        font-size: 28px;
        margin: 0;
        text-transform: uppercase;
    }
}

.woocommerce-message {
    border-top-color: #8fae1b;
    background-color: transparent;
    padding-left: 45px !important;
    padding-right: 0px !important;
    border-top-width: 2px;
    font-family: 'Lato';
    font-weight: 100;

    a.wc-forward {
        background-color: #475159;
        color: #fff;
        border-radius: 0px;
        font-weight: 100;
        height: 32px;
    }
}

.no-sale-items {
    color: black;
    padding-top: 150px;
    a {
        color: #475159;
        text-decoration: underline;
    }
}
