section#featured-collection {
    background: url() no-repeat center center;
    background-size: cover;
    @media(min-width: $screen-sm) {
        img {
            display: none;
        }
    }
    @media(max-width: $screen-sm) {
        background-image: none !important;
    }

    .content {
        background: rgba($brand-primary, .8);
        font-weight: 300;
        padding: 40px 30px 30px;
        text-align: center;

        @media (min-width: $screen-sm-min) {
            padding: 70px 50px;
            margin-left: 15px;
            margin-right: -5px;
        }

        .sub-title {
            color: #ccc9c0;
        }

        h1 {
            font-size: 32px;
            margin: 3px 0 0;
            text-transform: uppercase;

            &:after {
                background: #b3b2ad;
                content: '';
                display: block;
                height: 1px;
                margin: 23px auto 20px;
                width: 65px;
            }
        }

        .btn {
            border-color: #748584;
            color: #b2bdb7;
            margin-top: 23px;
            text-transform: uppercase;
        }
    }
}