#child-cat {
	background-color: #fff;
	min-height: 500px;
	header {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		height: 500px;
			.row, .container-fluid {
				height: 100%;
		}

		.category-header{

			&__video{
				overflow: hidden;
				position: relative;
				height: 100%;
				video{
					position: absolute;
				    top: 50%;
				    left: 50%;
				    min-width: 100%;
				    min-height: 100%;
				    width: auto;
				    height: auto;
				    z-index: 0;
				    -ms-transform: translateX(-50%) translateY(-50%);
				    -moz-transform: translateX(-50%) translateY(-50%);
				    -webkit-transform: translateX(-50%) translateY(-50%);
				    transform: translateX(-50%) translateY(-50%);
	                @media (max-width: $screen-sm-min) {
		               display: none; 
		            }
				}

				.category-video-overlay {
					width: 100%;
		            height: 100%;
		            background-color: black;
		            opacity: 1;
		            position: absolute;
		            top: 0;
		            left: 0;
		            z-index: 20;
		            transition: opacity 250ms;
		            &--active {
		                opacity: 0;
		            } 
				}

				.category-video {
					position: absolute;
		            top: -75%;
		            left: -75%;
		            width: 250%;
		            height: 250%;
		            border: 0;
		            @media(max-width: $screen-sm) {
		                top: -75%;
		                left: -75%;
		                width: 250%;
		                height: 250%;
		            }  
				}

			}
		}
	}

	.additional-information {
		font-size: 12px !important;
		color: #5c6160 !important;
		font-weight: 300;
	}

 	#breadcrumbs {
		margin-bottom: 75px !important;
		@media(max-width: $screen-md) {
			margin-bottom: 30px !important;
		}
	}

	.banner-text-overlay {
		background-color: rgba(#475159, .8);
		height: 100%;
		padding-top: 50px;
		padding-bottom: 50px;
		text-align: center;
		font-size: 14px;
		line-height: 1.5;
		color: #f2f2f2;
		font-weight: 100;
		z-index: 60;
	}

	.textual {
		max-width: 320px;
		margin: 0px auto;
	}

	.sub-title {
		font-size: 14px;
		color: #ccc9c0;
	}

	h1 {
		margin-top: 0px;
		line-height: 1.5;
		margin-bottom: 10px;

		&:after {
			background: #b3b2ad;
		    content: '';
		    display: none;
		    height: 1px;
		    margin: 14px auto 20px;
		    width: 65px;
		}
	}

	&.search-results {
		color: #000;
		h1 {
			color: #000;
			padding-top: 15px;
			text-align: center;
		}
	}

}

#child-category-content {
	min-height: 500px;
	background-color: #fff;
	color: #2f3540;

	.woocommerce-breadcrumb {
		margin: 0px;
		font-size: inherit;
	}

	section#breadcrumb {	
		#breadcrumbs {
			margin-bottom: 70px;
		}
	}
}


#sidebar-categories {
}
.product-item {
	margin-bottom: 65px;
	text-align: center;
	h1 {
		max-width: 200px;
		font-family: $font-family-serif;
		color: #2f3540;
		font-size: 16px;
		line-height: 1.7;
		margin: 0px auto 0px auto;
		text-transform: uppercase;
		&:after {
			display: none;
		}
	}

	.product-title-wrapper {
		border-bottom: 1px solid #f3f4f4;
	    padding-bottom: 15spx;
		margin: 15px 0px;
		min-height: 89px;
	}
	
	.product-price {
		font-family: $font-family-sans-serif;
		font-size: 18px;
		line-height: 1;
		margin-bottom: 24px;
		font-weight: 300;
		color: #2f3540;
		.woocommerce-Price-amount-regular {
			opacity: 1!important;
		}
		>.woocommerce-Price-amount {
			color: #2f3540;
			font-weight: 600;
		}
		del {
			opacity: 0.6;
			color: #2f3540;
			.woocommerce-Price-amount {
				color: #2f3540;
				font-weight: 600;
			}
		}
		ins {
			font-weight: 600;
			color: red;
		}
	}

	.product-image {
		transition: 250ms all;
		height: 250px;
		border: 2px solid #e6e4e0;
		padding: 40px;
		background-color: #fff;
		figure {
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			height: 100%;
			width: 100%;
			display: block;
			background-color: #fff;
		}
	}

&:hover {
	.product-image {
		border-color: #839795;
	}
}
}



.product-controls {
	a {
		display: block;
		width: 100%;
		color: #fff;
		background-color: #fff;
		height: 33px;
	}

	i {
	    font-size: 21px;
	    font-weight: bold;
	    padding-top: 5px;
	}

	.add-to-link {
		border: 2px solid #d48ea2;
		color: #d48ea2;
		transition: 250ms all;

		&:hover {
			background-color: #d48ea2;
			color: #fff;
		}

	}

	.view-link {
		border: 2px solid #c1cbca;
		color: #c1cbca;
		transition: 250ms all;

		&:hover {
			background-color: #c1cbca;
			color: #fff;
		}

	
	}
}
		