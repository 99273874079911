.woocommerce-account .woocommerce {
	min-height: 500px;
	text-align: left;

	label {
		text-transform: uppercase;
		font-family: $font-family-sans-serif;
	}

}

.woocommerce div.product form.cart .variations {
	margin-bottom: 0;
}

.woocommerce-MyAccount-navigation {
	width: 100% !important;
	float: none !important;
}

.woocommerce-MyAccount-content {
	width: 100% !important;
	float: none;

	input {
		height: 40px;
		padding-left: 15px;	
	}

	label {
		text-transform: uppercase;
		font-family: $font-family-sans-serif;

		font-weight: 200;
	}
	
	legend {
		padding-top: 15px;
	}

	input[type='submit'] {
		background-color: #a91d44;
		font-family: $font-family-serif;
		color: #fff;
		font-weight: 500;
		margin-top: 20px;
		text-transform: uppercase;
		border-radius: none;
	}

}

.woocommerce-MyAccount-navigation ul {
    text-transform: uppercase;
    padding-left: 0px;
    text-align: center;


	li {
    padding-bottom: 15px;
    padding-top: 15px;
    display: inline-block;
    margin-right: initial;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #0583f2;
    padding-left: 15px;
    padding-right: 15px;
    width: 16.4%;
    height: 35px;

	@media(max-width: 991px) {
		width: 33.2%;
		margin-bottom: 5px;
	}

	@media(max-width: 767px) {
		width: 49%;
	}

    a {

    	color: #fff;
    	display: block;
    	width: 100%;
    	height: 100%;
    	padding-top: 8px;    
    }
	}
}

#customer_login {

	h2 {
		text-transform: uppercase;
		font-weight: normal;
		color: #58595b;
}	

	input[type='text'], input[type='email'], input[type='password'] {
		height: 40px;
	}
	
	label {
		text-transform: uppercase;
		font-weight: 200;
	}

}

table.shop_table {
	.button {
		background-color: #0583f2 !important;
		color: #fff;
		border-radius: none;
		text-transform: uppercase;
	}
}

.woocommerce-result-count {
	display: none;
}


#child-cat.sale-template{

	#breadcrumbs{

		margin-bottom: 40px !important;

	}

	.woocommerce-Price-amount-regular{

		color: #839795;
		opacity: .6;
		margin-right: 5px;

	}

	.woocommerce-Price-amount-sale{

		font-weight: 700;
		
	}	

}


