#shop-sidebar {
	@media(max-width: $screen-md) {
		height: 35px;
	    // overflow: hidden;
		margin-bottom: 30px;
		display: inline-block;
    	width: 100%;
		h1 {
		    background-color: #475059;
		    font-family: 'Lato';
		    font-weight: 200 !important;
		    font-size: 16px !important;
		    padding-top: 4px;
		    padding-bottom: 4px;
		    text-align: center;
		    color: #fff !important;
		}
		&.open {
			height: auto;
		}
}


	.filter-sidebar {
		height: 20px;
	    overflow: hidden;
	    margin: 0 0 20px 0;
	    .open {
	    	display: inline-block;
	    	float: right;
	    }
	    .closed {
	    	display: none;
	    	float: right;
	    }
		&.active {
			height: auto;
			.open {
				display: none;
			}
			.closed {
				display: inline-block;
			}
		}


	    h2 {
	    	cursor: pointer;
	    }
	}

	h1 {
		font-size: 22px;
		color: #2f3540;
		margin-bottom: 35px;
		&:after {
			display: none;
		}
	}
	h2 {
		color: #2f3540;
		font-size: 15px;
		margin: 0px 0px 35px 0px;
	}
	color: #839795;

	ul, li {
		list-style-type: none;
		margin: 0px;
		padding: 0px;
	}

	li {
    	padding-left: 15px;
	    margin-bottom: 13px;
    	}

	ul {
		padding-bottom: 15px;
		&.orderby {
			padding-bottom: 35px;
		}
	}

	li.orderby-wrapper {
	    margin-bottom: 20px;
	}

	a {
	    color: #839795;
	    font-family: 'Lato';
	    font-weight: 100;
    	position: relative;

    	&.orderby-item {
		    display: block;
		    padding-left: 38px;
    	}
    	
		&.orderby-item:before {
		    content: '';
		    width: 25px;
		    height: 25px;
		    display: block;
		    border: 2px #e9e7e4 solid;
		    position: absolute;
		    left: 0;
		    border-radius: 3px;
		}
    }
}