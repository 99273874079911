section#categories {
    background: #fff;
    padding: 15px 0;
    
    a {
        text-decoration: none;
    }

    .category {
        background: #e6e7e8 url() no-repeat center center;
        background-size: cover;
        margin: 15px 0;
        min-height: 200px;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        
        //IE Fix
        height: 1px;

        @media (min-width: $screen-sm-min) {
            min-height: 290px;
        }

        a {
            text-decoration: none;
        }
        
        /*
        &.large {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 450px;

            @media (min-width: $screen-sm-min) {
                min-height: 610px;
            }

            .content {
                background: rgba(0, 19, 35, 0.64);
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                transform: none;
                top: auto;
                position: relative;
                left: auto;
                padding: 10% 5%;

                @media (min-width: $screen-sm-min) {
                    padding: 0 25%;
                }

                h3:after {
                    background: #72726e;
                    content: '';
                    display: block;
                    height: 2px;
                    margin: 22px auto;
                    width: 70px;
                }
            }
        }
        */

        .content {
            background: rgba(#001323, .6);
            left: 0;
            padding: 10px;
            text-align: center;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .sub-title {
                color: #fff;
                font-weight: 300;
                display: block;
                font-style: italic;
            }

            h3 {
                font-size: 28px;
                text-transform: uppercase;
                margin: 0;
            }

            p {
                font-weight: 300;
            }

            .btn {
                border-color: #748584;
                color: #b2bdb7;
                margin: 20px auto 0;
                max-width: 170px;
                text-transform: uppercase;
            }
        }
    }
}