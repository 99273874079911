#single-product {
	background-color: #fff;
	padding: 35px 0px;
	color: #2f3540;
	padding-top: 0px;

	.summary.entry-summary{

		@media(max-width: $screen-md) {

			margin-top: 20px;

		}

	}


	.woocommerce-tabs {
		margin: 50px 0 0 0;
		h2 {
			display: none;
		}
		.panel {
			border-radius: 0;
			border: 1px solid $brand-third;
			padding: 30px;
			font-family: $font-family-sans-serif;
			font-size: 14px;
			font-weight: 300;
			line-height: 1.6;
			h4 {
				margin: 0 0 30px 0;
			}
			p {
				margin: 0 0 30px 0;
			}
			a {
				color: $brand-primary;
				text-decoration: underline;
			}
			td {
				p {
					margin: 0;
				}
			}

			.shop_attributes {
				border-top: 1px solid $brand-third;
			}

			.shop_attributes th {
				font-weight: normal;
			}

			.shop_attributes th, .shop_attributes td {
				border-bottom: 1px solid $brand-third;
			}



		}

		.tabs.wc-tabs {
			background: none;
			padding: 0;
			overflow: visible;
			font-size: 0px;
			margin: 0;


			li {
				border-bottom: 0 !important;
				a {
					text-decoration: none;
				}
				clear: both;
				cursor: pointer;
				border-radius: 0;
				padding: 5px 15px;
				text-align: center;
				font-weight: 300;
				text-transform: uppercase;
				background: none;
				border-color: $brand-third;
				border-bottom: none;
				display: inline-block;
				border: 1px solid  #dadbdc;
				margin: 0 15px 0 0;

				@media(max-width: $screen-sm) {
					width: 100%;
					display: block;
					border-right: 1px solid $brand-third;
				}
				a {
					color: rgb(47, 53, 64);
					width: 100%;
					height: 100%;
					display: block;
					font-weight: 300;
					font-size: 14px;
					font-family: $font-family-serif;
				}
				&.active {
					background: $brand-secondary;
					a {
						color: #fff;
					}
				}
				&:before {
					display: none;
				}
				&:after {
					display: none;
				}
			}
			&:before {
				display: none;
			}
		}
	}

	#breadcrumbs {
		margin-bottom: 40px !important;
		padding-bottom: 0px;
	}

	.woocommerce-breadcrumb {
		margin-bottom: 0px;
	}

	.attachment-shop_thumbnail {
			width: 100px;
	}

	h3 {
		font-size: 16px;
		text-transform: uppercase;
		margin-bottom: 16px;
	}

	.product_image{

		max-width: 400px;
		max-height: 400px;

	}


	.bundled_products a {
		color: #5c6160 !important;
	}

	.product{

		.product_title{

			padding-right: 70px;

		}


		span.onsale{

			left: initial;
			right: 5px;
			width: 50px;
			height: 50px;
			border-radius: 50px;
			margin: 0;
			background: #a81c44;
			font-size: 14px;
			padding: 1px 0 0 2px;
			text-shadow: 1px 1px 3px #000;
			box-shadow: 1px 1px 1px #000;

		}
		
	}




	.single-product-input-field {
		&.input-type-file {
			border: 1px solid #9fa1a3;
			margin-bottom: 15px;

		label {
			display: none;
			height: 43px;
		}

		&.input-type-file {
			label {
				display: block;
			}
		}

		label {
			margin-bottom: 0px;
		}

		label::before {
 		   content: 'Choose Image: ';
 		   background-color: #e6e7e8;
 		   font-size: 14px;
 		   font-family: $font-family-sans-serif;
			display: inline-block;
			color: #58595b;
			padding-right: 45px;
			padding-left: 45px;
			vertical-align: middle;
			height: 100%;
			line-height: 2.9;
			font-weight: 100;
		}

		.label-text {
			display: inline-block;
			font-weight: 100;
			height: 100%;
			vertical-align: middle;
			line-height: 2.9;
			padding-left: 15px;
		}

		input[type='file'] {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}

		input {
			height: 45px;
			border: 1px solid #000;
		}

		input, textarea {
			width: 100%;
			display: block;
			margin-bottom: 13px;
		}
	}

	label {
		width: 100%;
		display: block;
	}

	.label-text {
		display: none;
	}

	textarea {
		padding: 10px 15px;
		display: block;
		width: 100%;
		font-weight: 100;
		font-family: $font-family-sans-serif;
		outline: none;
		font-size: 14px;
		border: 1px solid #aeb0b2;
		color: #5c6160;
		margin-bottom: 15px;
	}

	select {
		width: 100%;
		-webkit-apperance: none;
		border-radius: none;
		height: 40px;
		font-size: 14px;
		font-weight: 100;
		outline: none;
		border-radius: 0px;
		padding-left: 10px;
	}

	}



	button[type='submit'] {
			width: 100%;
			display: block;
			text-align: center;
			padding-left: 50px;
			background-color: #a91d44;
			border-radius: none;
			color: #fff;
			border: none;
			box-shadow: none;
			font-family: $font-family-serif;
			height: 46px;
			padding-top: 5px;
			background-image: url('../images/add-to-cart-icon.png');
			background-repeat: no-repeat;
			background-position: left 10px center;
			margin-top:0px;
	}


        .share-links {
            margin-top: 20px;
            text-align: center;
            margin-bottom: 50px;


            @media (min-width: $screen-sm-min) {
                margin-top: 0;
                text-align: right;
            }

            .share-label {
                color: #343434;
                display: block;
                font-family: $font-family-serif;
                font-size: 15px;
                margin-bottom: 10px;
                margin-right: 15px;
                text-transform: uppercase;
                vertical-align: middle;

                @media (min-width: $screen-sm-min) {
                    display: inline-block;
                    font-size: 12px;
                    margin-bottom: 0;
                }
            }

            .a2a_kit {
                display: inline-block;
                vertical-align: middle;

                a {
                    display: inline-block;
                    border: 1px solid $brand-secondary;
                    border-radius: 50%;
                    color: $brand-secondary;
                    font-size: 27px;
                    height: 46px;
                    line-height: 45px;
                    margin: 2px 10px 0;
                    text-align: center;
                    width: 46px;
                    cursor: pointer;

                    @media (min-width: $screen-sm-min) {
                        margin: 2px 22px 0;

                    }

                    &:first-of-type {
                        margin-left: 0;

                        @media (min-width: $screen-sm-min) {
                            margin-left: 22px;
                        }
                    }

                    &:active,
                    &:focus,
                    &:hover {
                        background: $brand-secondary;
                        color: #fff;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &.a2a_button_google_plus {
                        font-size: 20px;
                    }
                }
            }
        }

	.add-to-cart-row {
		padding-top: 20px;
		//padding-bottom: 25px;
	}

	.wcppec-checkout-buttons {
		overflow: initial!important;
	}

	select {
		// display: inline-block;
		// width: 45px;
		height: 45px;
		border: 1px solid #e9eaeb;
		background: white;
		border-radius: 0;
		margin: 0;
		-webkit-appearance: none;
		margin-bottom: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}

	form.cart .variations label {
		color: black;
		font-size: 12px;
		color: #343434;
		font-family: "Cinzel", serif;
		text-transform: uppercase;
	}

	.quantity-input, .product-type-variable form.cart div.quantity {
		label {
			display: inline-block;
			font-size: 12px;
			color: #343434;
			font-family: $font-family-serif;
			text-transform: uppercase;
			margin-right: 25px;
		}
		.product-type-variable form.cart div.quantity {
			clip: initial;
			position: initial !important;
			overflow: auto;
			width: auto;
			height: auto;
		}
		@media(max-width: $screen-md) {
			margin-bottom: 30px;
		}



		.quantity {
			display: inline-block;
			float: none;
		}

		input {
			display: inline-block;
			width: 45px;
			height: 45px;
			border: 1px solid #e9eaeb;
		}
	}

	.woocommerce-variation-price {
		margin-bottom: 10px;
		span.price {
			font-family: $font-family-sans-serif;
			font-size: 28px;
			color: #2f3540;
			font-weight: 600;
		}
	}

	.product-type-variable form.cart {

		.variations {
			&__quantity-row {
				> td.value {
					> .quantity > label {
						display: none;
					}
				}
			}
			td.value {
				width: 100%;
			}
			td {
				&.value {
					width: 100%;
				}
				&.label label {
					margin-top: 10px;
				}
			}
		}


		div.quantity {
			margin-bottom: 20px;
			label {
				min-width: 96px;
				margin: 0;
				text-align: left;
				clip: auto;
				height: auto;
				overflow: auto;
				position: static!important;
				width: auto;
				word-wrap: normal!important;
			}
		}
	}

	h1 {
		color: #2f3540;
		font-size: 36px;
		margin: 0px 0px 60px 0px;
		@media(max-width: $screen-sm) {
			font-size: 23px;
			margin-bottom: 30px;
		}
	}

	p.price {
		font-family: $font-family-sans-serif;
		font-size: 28px;
		margin-bottom: 20px;
		line-height: 1;
		font-weight: 100;
		color: #2f3540;
		>.woocommerce-Price-amount {
			color: #2f3540;
			font-weight: 600;
		}
		del {
			opacity: 0.6;
			color: #2f3540;
			.woocommerce-Price-amount {
				color: #2f3540;
				font-weight: 600;
			}
		}
		ins {
			font-weight: 600;
			color: red;
		}
	}

	.product-sku {
		margin-bottom: 30px;
		text-transform: uppercase;
		font-family: $font-family-serif;
		color: #5c6160;
		font-size: 12px;
		}

	.single-product-description {
		font-family: $font-family-sans-serif;
		color: #2f3540;
		font-size: 14px;
		font-weight: 100;
		padding: 40px 0px 25px 0px;
		border-top: 1px solid #e9eaeb;
		border-bottom: 1px solid #e9eaeb;

		p {
			margin-bottom: 15px;
		}
	}

	.main-slider-wrapper {
		height: 500px;
		border: 1px solid #f1f2f2;
		padding: 25px;
		position: relative;
		@media(max-width: $screen-md) {
			margin-bottom: 30px;
		}

		.zoom-icon {
			position: absolute;
			right: 10px;
			bottom: 15px;
			width: 48px;
			height: 48px;
			background: url('../images/zoom-icon.png') no-repeat center center;
			z-index: 100;
		}


		.main-slick-slider {
			height: 100%;

			.pane {
				height: 100%;
			    background-repeat: no-repeat;
			    background-size: 100%;
    			background-position: center center;
    			outline: none;
    			position: relative;

    			img {
    				position: absolute;
    				left: 50%;
    				top: 50%;
    				transform: translate(-50%, -50%);
					pointer-events: all;
					
					@media(max-width: $screen-sm) {
						object-fit: contain;
						position: initial;
						transform: none;
						left: 0;
						width: 100%;
						top: 0;
						height: 100%;
					}
    			}
				@media(max-width: $screen-sm) {
					.slick-slider-anchor {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
    		}

			.slick-list, .slick-track {
				height: 100%;
			}
		}




	}

	.thumb-slider-wrapper {
		height: 100px;
		margin-top: 25px;

		&.non-sliding {
			.thumb-slick-slider {
				padding: 0;
			}
		}

		.thumb-slick-slider {
			height: 100%;
			padding: 0px 58px 0px 58px;
			position: relative;


			.slick-list, .slick-track {
				height: 100%;
			}

			.slick-prev {
				position: absolute;
				left: 0px;
				top: 50%;
				width: 31px;
				height: 31px;
				background: url('../images/thumbnail-slider-left.png');
				z-index: 10;
				border: none;
				text-indent: -1000px;
				overflow: hidden;
				margin-top: -15.5px;
				box-shadow: 0px;
				outline: none;
			}

			.slick-next {
				outline: none;
				position: absolute;
				right: 0px;
				top: 50%;
				width: 31px;
				height: 31px;
				background: url('../images/thumbnail-slider-right.png');
				z-index: 10;
				border: none;
				text-indent: -1000px;
				overflow: hidden;
				margin-top: -15.5px;
				box-shadow: 0px;
			}

			.pane {
				height: 100%;
				background-repeat: no-repeat;
				border: 1px solid #e6e7e8;
				background-size: auto;
				background-position: center center;
				margin-left: 5px;
				padding: 10px;
				margin-right: 5px;
				outline: none;
			}
		}
	}

}

.woocommerce div.product form.cart .variations select {
    max-width: 100%;
    min-width: 75%;
    display: inline-block;
    margin-right: 1em;
    margin-top: 15px;
}
.woocommerce div.product p.price del, .woocommerce div.product span.price del {
	margin: 0 10px 0 0;
}
