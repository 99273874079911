@import "homepage/slider";

@import "homepage/categories";

@import "homepage/collection";

@import "homepage/jewellery";

@import "homepage/brands";

.featured-products-mobile {
	display: none;
	@media (max-width: $screen-sm) {
		display: block;
	}
}

.featured-products {
	display: block;
	@media (max-width: $screen-sm) {
		display: none;
	}
}