	#not-found {
		background-color: #fff;
		color: #000;
		padding: 70px 0px;
		text-align: center;
		
		.smiley {
			font-size: 50px;
			margin-bottom: 30px;
		}
	
		h1 {
			line-height: 1.5;
			margin: 0px;

		}
}