header#main-header {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            position: relative;
        }
    }

    .top {
        @media (min-width: $screen-md-min) {
            margin-bottom: 40px;
        }

        .links {
            padding: 5px 0;

            @media (min-width: $screen-sm-min) {
                border-bottom: 2px solid #657477;
            }

            .row-container {
                display: flex;
                flex-wrap: wrap;

                .quick-links {
                    padding: 2px;

                    li {
                        position: relative;

                        a {
                            color: #e6e7e8;
                            font-size: 11px;
                            font-weight: 300;
                            @media (max-width: $screen-md-min) {
                                font-size: 9px;
                            }


                            &:hover {
                                color: #838383;
                                text-decoration: none;
                            }
                        }

                        &:not(:last-of-type) {
                            &:after {
                                background: #e6e7e8;
                                content: '';
                                display: inline-block;
                                height: 10px;
                                width: 1px;
                                margin: 0 4px 0 8px;
                                position: relative;
                                top: 1px;
                            }
                        }
                    }
                }

                .social-links {
                    height: 100%;
                    text-align: center;
                    margin-top: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (min-width: $screen-sm-min) {
                        margin-top: 0;
                        text-align: right;
                        justify-content: flex-end;
                    }

                    .social-links-icon {
                        a {
                            border: 1px solid #ffffff;
                            border-radius: 50%;
                            background: #ffffff;
                            color: $brand-primary;
                            display: block;
                            font-size: 13px;
                            height: 24px;
                            text-align: center;
                            width: 24px;
                            margin-left: 8px;
                            text-decoration: none;

                            @media (min-width: $screen-sm-min) {
                                font-size: 14px;
                                height: 24px;
                                width: 24px;
                            }

                            &:hover {
                                color: #ffffff;
                                background: transparent; 
                            }

                            span {
                                line-height: 22px;
                                margin: 0 auto;
                                display: block;
                                text-align: center;
                                padding: 0 0 0 1px;
                                @media (min-width: $screen-sm-min) {
                                    line-height: 22px;
                                    padding: 0;
                                }
                            }
                        }
                    }

                    .social-links-email {
                        display: none;
                        @media (min-width: $screen-md-min) {
                            display: block;
                        }
                        a {
                            color: #e6e7e8;
                            font-size: 11px;
                            font-weight: 300;
                        } 
                    }

                    .social-email-icon {
                        display: block;
                        @media (min-width: $screen-md-min) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .logo {
        margin-top: 20px;
        margin-bottom: 25px;

        @media (min-width: $screen-md-min) {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .account-links {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        overflow: visible;
        width: 100%;
        margin-top: 5px;

        @media (min-width: $screen-sm-min) {
            margin-top: 0;
        }

        .btn {
            flex: 0 0 50%;
            font-size: 12px;
            overflow: hidden;
            padding: 8px 3px;
            text-overflow: ellipsis;
            text-transform: uppercase;

            &:hover {
                color: darken(#fff, 10%);
            }

            &.btn-register {
                background: #b2bdb7;

                @media (min-width: $screen-md-min) {
                    flex: 0 0 62%;
                }
            }

            &.btn-login {
                background: #839795;
                margin-left: auto;

                @media (min-width: $screen-md-min) {
                    flex: 1 1 0%;
                }
            }

            &.btn-account {
                @extend .btn-register;
            }

            &.btn-logout {
                @extend .btn-login;
                background: #a91d44;
            }

            span {
                margin-right: 3px;
            }
        }
    }

    .search {
        input {
            border: 1px solid #777e84;
            float: left;
            font-size: 11px;
            font-weight: 300;
            height: 36px;
            width: calc(100% - 70px);
            border-right: 0;
        }

        button {
            background: #839795;
            float: left;
            height: 36px;
            padding: 0;
            width: 70px;

            span {
                font-size: 23px;
            }
        }
    }

    .basket {
        display: inline-block;
        margin-bottom: 10px;
        width: 100%;
        
        @media (min-width: $screen-sm-min) {
            margin-bottom: 0;
        }

        .text {
            border: 1px solid #777e84;
            border-right: none;
            height: 36px;
            float: left;
            font-size: 11px;
            font-weight: 300;
            overflow: hidden;
            padding: 10px;
            text-overflow: ellipsis;
            text-rendering: auto;
            white-space: nowrap;
            width: calc(100% - 70px);
        }

        .icon {
            background: #a91d44;
            float: left;
            height: 36px;
            text-align: center;
            width: 70px;

            span {
                font-size: 23px;
                padding-top: 6px;
            }
        }
    }

    .navigation {
        text-align: center;
        margin: 15px 0;
        position: relative;
        z-index: 10;

        @media (min-width: $screen-md-min) {
            margin: 32px 0 13px;
            text-align: right;
        }

        ul {
            li {
                position: relative;
                ul {
                    display: none;
                    position: absolute;
                    left: -20px;
                    top: 100%;
                    background-color: $brand-primary;
                    padding: 20px 10px 10px 10px;
                    @media(max-width: $screen-sm) {
                        position: static;
                        display: block;
                    }
                }
                &:hover {
                    ul {
                        display: block;
                    }
                }
                a {
                    color: #ccc9c0;
                    font-family: $font-family-serif;
                    font-size: 13px;
                    text-transform: uppercase;

                    @media (min-width: $screen-md-min) {
                        font-size: 15px;
                    }

                    &:hover {
                        color: #fff;
                        text-decoration: none;
                    }
                }

                &.red {
                    a {
                        color: #ff2424;
                    }
                }

                &:not(:last-of-type) {
                    position: relative;

                    &:after {
                        background: #ccc9c0;
                        content: '';
                        display: inline-block;
                        height: 13px;
                        margin: 0 12px 0 15px;
                        position: relative;
                        top: 1px;
                        width: 1px;

                        @media (min-width: $screen-sm-min) {
                            margin: 0 6px 0 9px;
                        }

                        @media (min-width: $screen-lg-min) {
                            margin: 0 17px 0 21px;
                        }
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }

                &.current-menu-item {
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    .mobile-navigation {
        margin-top: 10px;
	text-align: center;
	

        .buttons {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            width: 100%;

            a {
                flex: 0 0 50%;

                &:active,
                &:focus,
                &:hover {
                    color: darken(#fff, 25%);
                }

                &:first-of-type {
                    background: darken($brand-primary, 10%);
                }

                &:last-of-type {
                    background: lighten($brand-primary, 10%);
                }
            }
        }

        #mobile-search {
            background: darken($brand-primary, 10%);
            display: none;

            form {
                display: flex;
                flex-direction: row;

                input {
                    background: #fff;
                    color: darken($brand-primary, 10%);
                    height: 40px;

                    @include placeholder(darken($brand-primary, 10%));
                }

                button {
                    background: darken($brand-primary, 10%);
                    padding: 0 10px;

                    span {
                        font-size: 24px;
                        font-weight: bold;
                    }
                }
            }
        }

        ul {
            background: lighten($brand-primary, 10%);
            padding: 10px 0 0;

            li {
                display: block;

                a {
                    display: block;
                    padding: 5px;
                    margin: 0 10px;
                }
            }
        }
    }
}

.menu-main-menu-container {
    text-align: center;
}
